import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Download,
} from '@mui/icons-material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Header from './Header';
import { t } from '../i18n';
import {
  ENDPOINT,
  PHOTOS_BOX_NAME,
  DELIVERY_INFORMATION_NAME,
  CAR_INFO_NAME,
  DETAILS_COLUMNS,
  // AUCTIONS_PHOTOS_NAME,
  // POLS_PHOTOS_NAME,
  // PODS_PHOTOS_NAME,
  PHOTOS_ROWS,
  DELIVERY_INFORMATION_FIELDS,
  CAR_INFORMATION_FIELDS,
  STATUSES_PILL_COLOR,
  STATUSES_PILL_BACKGROUND_COLOR,
} from '../constants';
import { prepareDataObj, itemInObjByString } from '../utils';
import nocar from '../images/nocar.png';

const Details = () => {
  const params = useParams();
  const [data, setData] = useState();
  const [openedPhotos, setOpenedPhotos] = useState([]);
  const [hovered, setHovered] = useState();

  const createCarouselItemImage = (index = {}) => (
    <div key={index}>
      <img alt="car" src={nocar} />
    </div>
  );

  const renderArrow = (isPrev, onClick, isShown) => {
    if (!isShown) return;
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        className={`sliderArrow ${isPrev ? 'prevArrow' : ''}`}
      >
        {isPrev ? <ChevronLeft viewBox="5 5 14 14" /> : <ChevronRight viewBox="5 5 14 14" />}
      </div>
    );
  };

  const renderPhotosBox = () => {
    const renderPhotoRow = (item) => {
      const isFieldOpened = openedPhotos.indexOf(item.name) !== -1;
      return (
        <div>
          <div
            className={`photosRow ${isFieldOpened ? 'openedRow' : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => {
              if (openedPhotos.indexOf(item.name) === -1) {
                setOpenedPhotos([...openedPhotos, item.name]);
              } else {
                setOpenedPhotos(openedPhotos.filter((openedItem) => openedItem !== item.name));
              }
            }}
          >
            <div className="left">
              <div
                className="circleIcon photosChevronIcon detailsIcon"
                onMouseEnter={() => {
                  setHovered(item.name);
                }}
                onMouseLeave={() => {
                  setHovered(null);
                }}
              >
                {[hovered, ...openedPhotos].indexOf(item.name) !== -1 ? (
                  <KeyboardArrowDown viewBox="0 0 26 24" />
                ) : (
                  <KeyboardArrowUp viewBox="0 0 26 24" />
                )}
              </div>
              <div className="photosRowTitle">{t(item.title)}</div>
            </div>
            <div className="circleIcon detailsIcon">
              <Download className="downloadIcon" />
            </div>
          </div>
          {/* TODO AUCTIONS_PHOTOS_NAME, POLS_PHOTOS_NAME, PODS_PHOTOS_NAME for dynamic photos */}
          {isFieldOpened && (
            <div className="sliderWrapper">
              <Carousel
                renderArrowPrev={(onClick, isShown) => renderArrow(true, onClick, isShown)}
                renderArrowNext={(onClick, isShown) => renderArrow(false, onClick, isShown)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(createCarouselItemImage)}
              </Carousel>
            </div>
          )}
        </div>
      );
    };

    return <div className="detailsPhotosWrapper">{PHOTOS_ROWS.map(renderPhotoRow)}</div>;
  };

  const renderStatusValue = (val) => {
    if (!val) return;
    const pillStyle = {
      background: STATUSES_PILL_BACKGROUND_COLOR[val.toLowerCase()],
      color: STATUSES_PILL_COLOR[val.toLowerCase()],
    };
    return (
      <div style={pillStyle} className="statusPill carInfoStatus" title={val}>
        <div style={{ lineHeight: 'normal' }}>{val}</div>
      </div>
    );
  };

  const renderFields = (fields) => (
    <div className="detailsFields">
      {fields.map((field) => (
        <div className="detailsField">
          <div className="fieldLabel">{t(field.label)}</div>
          <div className="fieldValue">
            {field.name === 'status'
              ? renderStatusValue(itemInObjByString(data, field.name))
              : itemInObjByString(data, field.name)}
          </div>
        </div>
      ))}
    </div>
  );

  const renderColumnContent = (name) => {
    switch (name) {
      case PHOTOS_BOX_NAME:
        return renderPhotosBox();
      case DELIVERY_INFORMATION_NAME:
        return renderFields(DELIVERY_INFORMATION_FIELDS);
      case CAR_INFO_NAME:
        return renderFields(CAR_INFORMATION_FIELDS);
      default:
        return null;
    }
  };

  const renderColumn = (item) => (
    <div className="column">
      <div className="subTitle">{t(item.title)}</div>
      <div>{renderColumnContent(item.name)}</div>
    </div>
  );

  const getData = async () => {
    const orderData = await axios.get(
      `${ENDPOINT}/webresources/anon/getOrderDetailsAnon?orderNum=${params.orderNum}`,
    );
    setData(prepareDataObj(orderData?.data?.data));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div stlye={{ color: 'black' }}>
      <Header isDetailsPage />
      {data && (
        <div className="contentWrapper">
          <div className="detailsWrapper">
            <div className="title">{`${data?.make} ${data?.model} ${data?.year} ${data?.vinNumber}`}</div>
            <div className="columns">{DETAILS_COLUMNS.map((item) => renderColumn(item))}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
