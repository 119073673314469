import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FilterAlt, Logout, Menu } from '@mui/icons-material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import HeaderSearchTextField from './HeaderSearchTextField';
import { t } from '../i18n';
import AppContext from '../AppContext';
import { ENDPOINT, VIEWS, STATUS_ACTION_NAME } from '../constants';
import { prepareStatuses } from '../utils';
// images:
import logo from '../images/logo.png';
import detailsLogo from '../images/details-logo.png';
import user from '../images/user.svg';
import sync from '../images/sync.png';
// end of images

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 285,
  },
  cssLabel: {
    color: '#fff',
    '&.Mui-focused': {
      color: '#fff',
    },
  },
  cssOutlinedInput: {
    '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
      borderColor: '#fff', // default
    },
    '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
      borderColor: '#fff', // hovered #DCDCDC
    },
    '&$cssFocused $notchedOutline': {
      borderColor: '#fff', // focused
    },
  },
  notchedOutline: {},
  cssFocused: {},
  error: {},
  disabled: {},
  underline: {
    '&:before': {
      borderBottom: '1px solid rgba(255, 133, 51, 0.42)',
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
});

const Header = ({ onMenuClick, setStatuses, toggleSidebar, isDetailsPage }) => {
  const context = useContext(AppContext);
  const { navigateToLogin, setSearch, toggleLoader, token, setError } = context;
  const [showMenuItems, setShowMenuItems] = useState(false);
  const searchInputRef = useRef();
  const anchorRef = React.useRef(null);

  const syncWith1c = async () => {
    toggleLoader(true);
    await axios.get(`${ENDPOINT}/webresources/auction/doSyncFrom1c?token=${token}`);
    toggleLoader(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setShowMenuItems(false);
  };

  const getStatuses = async () => {
    if (!token) return;
    try {
      toggleLoader(true);
      const statusesResponse = await axios.get(
        `${ENDPOINT}/webresources/auction/getStatuses?token=${token}`,
      );
      setStatuses(prepareStatuses(statusesResponse.data.data));
      toggleLoader(false);
    } catch (e) {
      setError(t('somethingWentWrong'));
      toggleLoader(false);
    }
  };

  useEffect(() => {
    if (!isDetailsPage) getStatuses();
  }, [token]);

  return (
    <div className="header">
      <div className="innerWrapper">
        <div className="left">
          <img className="logo" src={isDetailsPage ? detailsLogo : logo} alt="" />
          {!isDetailsPage && [
            <div className="search">
              <HeaderSearchTextField
                placeholder={t('search')}
                inputRef={searchInputRef}
                onBlur={() => {
                  setSearch(searchInputRef.current.value);
                }}
                onKeyPress={(e) => {
                  if (e.nativeEvent.keyCode === 13) {
                    if (searchInputRef.current) searchInputRef.current.blur();
                    setSearch(searchInputRef.current.value);
                  }
                }}
              />
            </div>,
            <Button
              onClick={() => {
                toggleSidebar(STATUS_ACTION_NAME);
              }}
              className="button filterButton"
              variant="contained"
            >
              <FilterAlt />
              {t('status')}
            </Button>,
          ]}
        </div>
        {!isDetailsPage && (
          <div className="right">
            <ButtonGroup
              className="menu"
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button
                size="small"
                aria-controls={showMenuItems ? 'split-button-menu' : undefined}
                aria-expanded={showMenuItems ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => setShowMenuItems(!showMenuItems)}
              >
                <Menu />
              </Button>
            </ButtonGroup>
            <Popper
              open={showMenuItems}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              className="headerMenuDropdown"
              placement="bottom-end"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {VIEWS.map((option) => (
                          <MenuItem
                            key={option.name}
                            onClick={() => {
                              onMenuClick(option.name);
                              setShowMenuItems(!showMenuItems);
                            }}
                            className="headerDropdownItem"
                          >
                            {t(option.label)}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div className="logout" onClick={syncWith1c} role="button" tabIndex={0}>
              <img src={sync} alt="" />
            </div>
            <div className="user">
              <img src={user} alt="" />
              {t('user')}
            </div>
            <div className="logout" onClick={navigateToLogin} role="button" tabIndex={0}>
              <Logout />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  onMenuClick: PropTypes.func,
  view: PropTypes.string,
  toggleSidebar: PropTypes.func,
  setStatuses: PropTypes.func,
  isDetailsPage: PropTypes.bool,
};

export default withStyles(styles)(Header);
