import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Article,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  Download,
  Edit,
  Add,
  Save,
  Delete,
  Image,
} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { t } from '../i18n';
import AppContext from '../AppContext';
import {
  FIELD_TYPE_TEXT,
  FIELD_TYPE_PHOTOS,
  FIELD_TYPE_CURRENCY,
  FIELD_TYPE_FILE,
  PERMISSION_AUCTION_UPLOAD,
} from '../constants';
import cashIcon from '../images/cash.svg';
import userIcon from '../images/user-field.svg';
import managerIcon from '../images/manager.svg';

const SidebarField = ({
  fieldOpened,
  isSidebarViewerOpened,
  setIsSidebarViewerOpened,
  setFieldOpened,
  field,
  fieldIndex,
  data,
}) => {
  const context = useContext(AppContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileName, setFileName] = useState(t('fileName'));
  const { user, toggleLoader } = context;
  const {
    name,
    label,
    type,
    hasCashIcon,
    hasManagerIcon,
    hasUserIcon,
    textColor,
    colorDependsOnValue,
    boldLabel,
  } = field;
  const isFieldOpened = fieldOpened === name;
  const isPhotosField = type === FIELD_TYPE_PHOTOS;
  let value;
  if (data && name) {
    value = data[name];
  }
  let color = textColor;
  if (colorDependsOnValue && value) color = value > 0 ? '#219653' : '#fd705f';
  const userCanUpload = user?.permissions.indexOf(PERMISSION_AUCTION_UPLOAD) !== -1;
  const showNewFileField = type === FIELD_TYPE_FILE && fieldIndex === 0 && userCanUpload;

  const onEditFile = () => {
    setIsEditMode(!isEditMode);
  };

  const onAddNewFile = () => {};

  const onSaveNewFile = () => {};

  const onDeleteFile = (fileData, fileIndex) => {
    console.log(fileData);
    console.log(fileIndex);
    // debugger;
  };

  const onDownload = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === FIELD_TYPE_FILE) {
      toggleLoader(true);
      fetch(value, {
        method: 'GET',
        mode: 'no-cors',
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = value.substring(value.lastIndexOf('/') + 1); // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          toggleLoader(false);
        });
      return;
    }

    // const downloadRequests = [];
    // if (value && value.length > 0) {
    //   value.forEach((img) => {
    //     downloadRequests.push(
    //       fetch(img.url, {
    //         mode: 'no-cors',
    //       }),
    //     );
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       const link = document.createElement('a');
    //       link.href = URL.createObjectURL(blob);
    //       link.download = `car_${index}.jpg`;
    //       link.click();
    //     })
    //     .catch(console.error);
    //   });
    //   const result = await Promise.all(downloadRequests);
    // }

    // fetch(value[0].url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'kiss.jpg';
    //     link.click();
    //   })
    //   .catch(console.error);
    setIsFileUploaded(false); // TODO
  };

  const toggleFieldOpened = (event) => {
    const elClasses = event?.target?.parentElement?.className?.baseVal;
    if (elClasses && elClasses.indexOf('editIcon') !== -1) return;
    if (isPhotosField) {
      if (isFieldOpened) {
        setFieldOpened(null);
      } else {
        setFieldOpened(name);
      }
      window.sidebarImageViewerImages = value.map((item) => item.url);
      setIsSidebarViewerOpened(!isSidebarViewerOpened);
    }
  };

  const renderDropdownIcon = () => {
    let iconContent;
    if (isPhotosField) {
      iconContent = isFieldOpened ? (
        <div className="circleIcon photosChevronIcon">
          <KeyboardArrowLeft viewBox="0 0 26 24" />
        </div>
      ) : (
        <KeyboardArrowDown viewBox="0 0 26 24" />
      );
    }
    return iconContent;
  };

  const renderBaseFieldValue = (val) => (
    <div style={color ? { color } : {}} className="value">
      {val}
    </div>
  );

  const renderCurrencyFieldValue = () => (
    <div className="rightSide">{renderBaseFieldValue(`${value || 0}$`)}</div>
  );

  const renderTextFieldValue = () => (
    <div className="rightSide">{value && renderBaseFieldValue(value)}</div>
  );

  const renderFileFieldValue = () => {
    let iconContent = <Download className="downloadIcon" onClick={onDownload} />;
    let className = 'hoverIcon';
    if (userCanUpload) {
      if (type === FIELD_TYPE_PHOTOS) {
        iconContent = <Edit className="editIcon" onClick={onEditFile} />;
      } else {
        iconContent = <Delete className="editIcon" onClick={() => onDeleteFile(value)} />;
        className = `${className} redIcon`;
      }
    }
    return <div className={className}>{iconContent}</div>;
  };

  const renderBaseFieldLabel = () => (
    <div style={color ? { color } : {}} className={`label ${boldLabel ? 'labelBold' : ''}`}>
      {label}
    </div>
  );

  const renderTextFieldLabel = () => (
    <div className="leftSide">
      {hasManagerIcon && <img className="managerIcon leftFieldIcon" src={managerIcon} alt="" />}
      {hasUserIcon && <img className="userIcon leftFieldIcon" src={userIcon} alt="" />}
      {label && renderBaseFieldLabel()}
    </div>
  );

  const renderPhotosFieldLabel = () => (
    <div className="leftSide filesFiledWrapper">
      {renderDropdownIcon()}
      {label && renderBaseFieldLabel()}
      {renderFileFieldValue()}
    </div>
  );

  const renderFileFieldLabel = () => (
    <div className="leftSide filesFiledWrapper">
      <Article />
      {label && (
        <a
          alt={label}
          download="kiss me"
          href={value}
          rel="noreferrer"
          target="_blank"
          className="label fileLabel"
        >
          {label}
        </a>
      )}
      {renderFileFieldValue()}
    </div>
  );

  const renderCurrencyFieldLabel = () => {
    if (!hasCashIcon) return renderTextFieldLabel();
    return (
      <div className="leftSide">
        <img className="cashIcon leftFieldIcon" src={cashIcon} alt="" />
        {label && renderBaseFieldLabel()}
      </div>
    );
  };

  const renderNewFileFieldLabel = () => (
    <div className="leftSide">
      <div className="circleIcon">
        <Add onClick={onAddNewFile} />
      </div>
      <TextField
        onChange={(event) => setFileName(event.target.value)}
        value={fileName}
        className="fieldInput"
        variant="outlined"
        disabled={!isFileUploaded}
      />
    </div>
  );

  const renderNewFileField = () => (
    <div className="fieldWrapper newFileWrapper">
      {renderNewFileFieldLabel()}
      <div className="rightSide">
        <div className={`circleIcon ${isFileUploaded ? '' : 'disabledIcon'}`}>
          <Save onClick={onSaveNewFile} />
        </div>
      </div>
    </div>
  );

  const renderFieldPart = (isLabel) => {
    let fieldPartContent;
    switch (type) {
      case FIELD_TYPE_TEXT:
        fieldPartContent = isLabel ? renderTextFieldLabel() : renderTextFieldValue();
        break;
      case FIELD_TYPE_PHOTOS:
        fieldPartContent = isLabel ? renderPhotosFieldLabel() : null;
        break;
      case FIELD_TYPE_FILE:
        fieldPartContent = isLabel ? renderFileFieldLabel() : null;
        break;
      case FIELD_TYPE_CURRENCY:
        fieldPartContent = isLabel ? renderCurrencyFieldLabel() : renderCurrencyFieldValue();
        break;
      default:
        fieldPartContent = isLabel ? renderTextFieldLabel() : renderTextFieldValue();
    }
    return fieldPartContent;
  };

  const content = [
    <div className="field">
      <div
        className={`fieldWrapper ${isPhotosField ? 'photosField' : ''}`}
        role="button"
        tabIndex={0}
        onClick={toggleFieldOpened}
      >
        {renderFieldPart(true)}
        {renderFieldPart()}
      </div>
      {/* For photos field im admin mode: */}
      {isEditMode && [
        renderNewFileField(),
        value.map((fileItem, fileIndex) => (
          <div className="fieldWrapper">
            <div className="leftSide">
              <div className="circleIcon greyIcon">
                <Image />
              </div>
              <div className="label labelBold fileLabel">{`Photo #${fileIndex}`}</div>
            </div>
            <div className="rightSide">
              <div className="hoverIcon redIcon">
                <Delete onClick={() => onDeleteFile(fileItem, fileIndex)} />
              </div>
            </div>
          </div>
        )),
      ]}
    </div>,
  ];

  if (showNewFileField) {
    content.unshift(<div className="field">{renderNewFileField()}</div>);
  }

  return content;
};

SidebarField.propTypes = {
  fieldOpened: PropTypes.bool,
  isSidebarViewerOpened: PropTypes.bool,
  setIsSidebarViewerOpened: PropTypes.func,
  setFieldOpened: PropTypes.func,
  field: PropTypes.shape,
  data: PropTypes.shape,
  fieldIndex: PropTypes.number,
};

export default SidebarField;
