import menu1 from './images/menu1.svg';
import menu2 from './images/menu2.svg';
import menu3 from './images/menu3.svg';

// export const ENDPOINT = 'http://localhost:8082'; // change later to empty string (?)
export const ENDPOINT = 'https://luckkeeper.com'; // change later to empty string (?)

export const PERMISSION_AUCTION_READ = 'AUCTION_READ';
export const PERMISSION_AUCTION_MANAGER = 'AUCTION_MANAGER';
export const PERMISSION_AUCTION_UPDATE = 'AUCTION_UPDATE';
export const PERMISSION_AUCTION_UPLOAD = 'AUCTION_UPLOAD';

export const VIEW_TRACKING = 'tracking';
export const VIEW_CALCULATOR = 'calculator';
export const VIEW_USERS = 'users';
export const VIEWS = [
  {
    name: VIEW_TRACKING,
    label: 'tracking',
    icon: menu1,
  },
  {
    name: VIEW_CALCULATOR,
    label: 'calculator',
    icon: menu2,
  },
  {
    name: VIEW_USERS,
    label: 'users',
    icon: menu3,
  },
];
export const STATUSES_PILL_BACKGROUND_COLOR = {
  new: '#d2e9d1',
  shipped: '#c1e1ff',
  released: '#d2e9d1',
  finalized: '#d3d3d3',
  delievered: '#c1e1ff',
  released_with_debts: '#e1df9e',
};
export const STATUSES_PILL_COLOR = {
  new: '#6db86a',
  shipped: '#2897ff',
  released: '#6db86a',
  finalized: '#464646',
  delievered: '#2897ff',
  released_with_debts: '#a1971d',
};

export const USERS_ACTIONS_PILL_BACKGROUND_COLOR = {
  active: '#fff2f2',
  inactive: '#f2f8ea',
  pending: '#f2f8ea',
  unregistered: '#d2e9d1',
};
export const USERS_ACTIONS_PILL_COLOR = {
  active: '#ef4246',
  inactive: '#299556',
  pending: '#299556',
  unregistered: '#111111',
};
export const USERS_ACTIONS = {
  active: 'Delete',
  inactive: 'Activate',
  pending: 'Confirm',
  unregistered: 'Request',
};

export const SORT_COLUMN_NAMES_MAP = {};

export const DOCUMENTS_ACTION_NAME = 'documents';
export const CAR_ACTION_NAME = 'car';
export const PRICING_ACTION_NAME = 'pricing';
export const SHIPPING_ACTION_NAME = 'shipping';
export const CHAT_ACTION_NAME = 'chat';
export const STATUS_ACTION_NAME = 'status';

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_PHOTOS = 'photos';
export const FIELD_TYPE_CURRENCY = 'currency';
export const FIELD_TYPE_FILE = 'file';
export const SECTION = 'section';
export const HISTORY = 'history';

export const DOCUMENT_BOX_TYPE = 'Document Box';
export const INVOICE_BOX_TYPE = 'Invoices Box';

export const CAR_ACTION_NAME_FIELDS = [
  {
    title: 'carInfo',
    type: SECTION,
    items: [
      {
        name: 'auction',
        label: 'auction',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'lotNumber',
        label: 'lotNumber',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'date',
        label: 'date',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'title',
        label: 'title',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'key',
        label: 'key',
        type: FIELD_TYPE_TEXT,
      },
    ],
  },
  {
    title: 'invoicesBox',
    type: SECTION,
    items: [
      {
        name: 'auctionPhotos',
        label: 'auctionsPhotos',
        type: FIELD_TYPE_PHOTOS,
      },
      {
        name: 'podsPhotos',
        label: 'podsPhotos',
        type: FIELD_TYPE_PHOTOS,
      },
      {
        name: 'polsPhotos',
        label: 'polsPhotos',
        type: FIELD_TYPE_PHOTOS,
      },
    ],
  },
];

export const SHIPPING_ACTION_NAME_FIELDS = [
  {
    title: 'logisticsBox',
    type: SECTION,
    items: [
      {
        name: 'dispatchPlace',
        label: 'dispatchPlace',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'loadDate',
        label: 'loadDate',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'pol',
        label: 'pol',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'shipDate',
        label: 'shipDate',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'pod',
        label: 'pod',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'eta',
        label: 'eta',
        type: FIELD_TYPE_TEXT,
      },
      {
        name: 'releasedDate',
        label: 'releasedDate',
        type: FIELD_TYPE_TEXT,
      },
    ],
  },
];

export const TOTAL_INCOME_FIELD = 'TOTAL_INCOME_FIELD';

export const PRICING_ACTION_NAME_FIELDS = [
  {
    title: null,
    type: SECTION,
    items: [
      {
        name: 'manager',
        label: 'manager',
        type: FIELD_TYPE_TEXT,
        hasManagerIcon: true,
      },
      {
        name: 'customer',
        label: 'customer',
        type: FIELD_TYPE_TEXT,
        hasUserIcon: true,
      },
    ],
  },
  {
    title: 'carCalculationBox',
    type: SECTION,
    items: [
      {
        name: 'brokerPrice',
        label: 'brokerPrice',
        type: FIELD_TYPE_CURRENCY,
        hasCashIcon: true,
      },
      {
        name: 'lotPrice',
        label: 'lotPrice',
        type: FIELD_TYPE_CURRENCY,
        hasCashIcon: true,
      },
      {
        name: 'taxes',
        label: 'taxes',
        type: FIELD_TYPE_CURRENCY,
        hasCashIcon: true,
      },
      {
        name: 'logPrice',
        label: 'logPrice',
        type: FIELD_TYPE_CURRENCY,
        hasCashIcon: true,
      },
      {
        name: TOTAL_INCOME_FIELD,
        label: 'totalIncome',
        type: FIELD_TYPE_CURRENCY,
        hasCashIcon: true,
        boldLabel: true,
        textColor: '#219653',
      },
    ],
  },
  {
    title: 'carbuyPaymentsHistory',
    type: SECTION,
    name: 'history',
    hasCashIcon: true,
    isHistory: true,
  },
  {
    title: null,
    type: SECTION,
    items: [
      {
        name: 'balance',
        label: 'balance',
        type: FIELD_TYPE_CURRENCY,
        colorDependsOnValue: true,
        boldLabel: true,
      },
      {
        name: 'credit',
        label: 'сredit',
        type: FIELD_TYPE_CURRENCY,
        textColor: '#219653',
        boldLabel: true,
      },
    ],
  },
];

export const DOCUMENTS_ACTION_NAME_FIELDS = [
  {
    name: 'order',
    label: 'order',
    type: FIELD_TYPE_TEXT,
  },
  {
    name: 'container',
    label: 'container',
    type: FIELD_TYPE_TEXT,
  },
  {
    name: 'vinNumber',
    label: 'vin',
    type: FIELD_TYPE_TEXT,
  },
  {
    title: 'documentsBox',
    type: SECTION,
    isFiles: true,
    name: 'documents',
  },
  {
    title: 'invoicesBox',
    type: SECTION,
    isFiles: true,
    name: 'invoices',
  },
];

export const PHOTOS_BOX_NAME = 'PHOTOS_BOX_NAME';
export const DELIVERY_INFORMATION_NAME = 'DELIVERY_INFORMATION_NAME';
export const CAR_INFO_NAME = 'CAR_INFO_NAME';

export const DETAILS_COLUMNS = [
  {
    name: PHOTOS_BOX_NAME,
    title: 'photosBox',
  },
  {
    name: DELIVERY_INFORMATION_NAME,
    title: 'deliveryInformation',
  },
  {
    name: CAR_INFO_NAME,
    title: 'carInfo',
  },
];

export const AUCTIONS_PHOTOS_NAME = 'AUCTIONS_PHOTOS_NAME';
export const POLS_PHOTOS_NAME = 'POLS_PHOTOS_NAME';
export const PODS_PHOTOS_NAME = 'PODS_PHOTOS_NAME';

export const PHOTOS_ROWS = [
  {
    name: AUCTIONS_PHOTOS_NAME,
    title: 'auctionsPhotos',
  },
  {
    name: POLS_PHOTOS_NAME,
    title: 'polsPhotos',
  },
  {
    name: PODS_PHOTOS_NAME,
    title: 'podsPhotos',
  },
];

export const DELIVERY_INFORMATION_FIELDS = [
  {
    name: 'status',
    label: 'status',
  },
  {
    name: 'shipping.dispatchPlace',
    label: 'dispatchPlace',
  },
  {
    name: 'shipping.loadDate',
    label: 'pickUpDate',
  },
  {
    name: 'shipping.pol',
    label: 'pol',
  },
  {
    name: 'containerLine',
    label: 'contLine',
  },
  {
    name: 'container',
    label: 'container',
  },
  {
    name: 'shipping.pod',
    label: 'pod',
  },
  {
    name: 'shipping.eta',
    label: 'eta',
  },
  {
    name: 'shipping.releasedDate',
    label: 'releasedDate',
  },
];

export const CAR_INFORMATION_FIELDS = [
  {
    name: 'car.auction',
    label: 'auction',
  },
  {
    name: 'car.lotNumber',
    label: 'lotNumber',
  },
  {
    name: 'car.date',
    label: 'date',
  },
  {
    name: 'car.title',
    label: 'title',
  },
  {
    name: 'car.key',
    label: 'key',
  },
];
