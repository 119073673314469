import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../i18n';
import statusCheck from '../images/statusCheck.svg';
import { STATUSES_PILL_COLOR } from '../constants';

const SidebarStatuses = ({ statuses, selectedStatuses, setSelectedStatuses }) => {
  const toggleSelected = (status) => {
    if (selectedStatuses.indexOf(status) !== -1) {
      setSelectedStatuses(selectedStatuses.filter((item) => item !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const renderStatus = (item) => {
    const isSelected = selectedStatuses.indexOf(item.name) !== -1;
    return (
      <div className="field">
        <div
          role="button"
          tabIndex={0}
          className="fieldWrapper statusField"
          onClick={() => toggleSelected(item.name)}
        >
          <div className={`statusCheckbox ${isSelected ? 'selected' : ''}`}>
            {isSelected && <img src={statusCheck} alt="" />}
          </div>
          <span style={{ fontWeight: 500, color: STATUSES_PILL_COLOR[item.name.toLowerCase()] }}>
            {item.label}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="sidebarFields">
      <div className="sidebarFieldsSection">
        <div className="title">{t('filtersByStatus')}</div>
        <div className="fieldsWrapper">{statuses.map(renderStatus)}</div>
      </div>
    </div>
  );
};

SidebarStatuses.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.string),
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  setSelectedStatuses: PropTypes.func,
};

export default SidebarStatuses;
