import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios/index';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { append } from '../i18n';
import {
  ENDPOINT,
  USERS_ACTIONS_PILL_COLOR,
  USERS_ACTIONS_PILL_BACKGROUND_COLOR,
  USERS_ACTIONS,
} from '../constants';
import { prepareUsersTableData } from '../utils';
import AppContext from '../AppContext';

const DEFAULT_SORT = {
  sortColumn: 'surname',
  sortOrder: 'ASC',
};

const getMeta = () => [
  { field: 'lastName', headerName: 'Surname', sortable: true, flex: 1.5 },
  { field: 'firstName', headerName: 'Name', sortable: true, flex: 1.5 },
  { field: 'phone', headerName: 'Number', sortable: true, flex: 1 },
  { field: 'role', headerName: 'Role', sortable: true, flex: 1.5 },
  { field: 'status', headerName: 'Status', sortable: true, flex: 0.5 },
  {
    field: 'actions',
    headerName: 'Action',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const val = params.value || '';
      const actionValue = USERS_ACTIONS[val.toLowerCase()];
      const pillStyle = {
        background: USERS_ACTIONS_PILL_BACKGROUND_COLOR[val.toLowerCase()],
        color: USERS_ACTIONS_PILL_COLOR[val.toLowerCase()],
      };
      return (
        <div style={pillStyle} className="status-pill">
          <div style={{ lineHeight: 'normal' }}>{actionValue}</div>
        </div>
      );
    },
  },
];

const Users = () => {
  const context = useContext(AppContext);
  const { toggleLoader, token, navigateToLogin, search, setUser, setError } = context;
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const sortOrder = useRef(DEFAULT_SORT.sortOrder);
  const sortColumn = useRef(DEFAULT_SORT.sortColumn);
  const selectedRowData = useRef();

  const getData = async (page = pageNumber) => {
    let result;
    // eslint-disable-next-line
    try {
      toggleLoader(true);
      const params = {
        matcher: search,
        page,
        sortColumn: sortColumn.current,
        sortOrder: sortOrder.current.toUpperCase(),
      };
      result = await axios.post(`${ENDPOINT}/webresources/auction/findClients/${token}`, params);
      toggleLoader(false);
    } catch (e) {
      setError('Что-то пошло не так. Пожалуйста перелогиньтесь');
      toggleLoader(false);
      return;
    }
    if (!result?.data?.data) return;
    const { content, page: newPage, recordsOnPage, totalRecords } = result.data.data;

    setPageNumber(newPage);
    setPerPage(recordsOnPage);
    setTotalCount(totalRecords);
    setData(prepareUsersTableData(content));
  };

  const getUser = async () => {
    let result;
    try {
      toggleLoader(true);
      result = await axios.get(`${ENDPOINT}/webresources/auth/getUser?token=${token}`);
      toggleLoader(false);
    } catch (e) {
      navigateToLogin('Сессия истекла. Пожалуйста залогиньтесь.');
      toggleLoader(false);
      return;
    }
    const { data: userData } = result.data;
    if (userData) {
      append(require(`../localization/${userData.locale}`));
      setUser({
        role: userData?.role,
        permissions: userData?.permissions,
      });
    }
  };

  const onActionClick = (actionName, rowData) => {
    console.log('action clicked');
    console.log(actionName);
    selectedRowData.current = rowData;
  };

  useEffect(() => {
    getData();
  }, [search]);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={data}
        columns={getMeta(onActionClick)}
        pageSize={perPage}
        rowCount={totalCount}
        paginationMode="server"
        sortingMode="server"
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        autoHeight
        disableVirtualization
        disableColumnResize
        disableSelectionOnClick
        rowHeight={95}
        resizable
        onSortModelChange={(sort) => {
          if (sort && sort[0]) {
            sortColumn.current = sort[0].field;
            sortOrder.current = sort[0].sort;
          } else {
            sortColumn.current = DEFAULT_SORT.sortColumn;
            sortOrder.current = DEFAULT_SORT.sortOrder;
          }
          getData();
        }}
        components={{
          ColumnSortedAscendingIcon: KeyboardArrowDown,
          ColumnSortedDescendingIcon: KeyboardArrowUp,
          ColumnUnsortedIcon: null,
        }}
        onPageChange={(page) => {
          getData(page + 1); // As data grid starts with page = 0
        }}
      />
    </div>
  );
};
export default Users;
