import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import nocar from '../images/nocar.png';
import AppContext from '../AppContext';
import Sidebar from './Sidebar';
import { append, t } from '../i18n';
import {
  ENDPOINT,
  STATUSES_PILL_BACKGROUND_COLOR,
  STATUSES_PILL_COLOR,
  DOCUMENTS_ACTION_NAME,
  STATUS_ACTION_NAME,
} from '../constants';
import { mapSortColumn, prepareTrackingTableData } from '../utils';

const DEFAULT_SORT = {
  sortColumn: 'orderNumber',
  sortOrder: 'ASC',
};

const getMeta = () => [
  {
    field: 'order',
    headerName: t('order'),
    sortable: true,
    width: 80,
    renderCell: (params) => {
      const val = params.value.slice(3);
      return (
        <div>
          <div style={{ lineHeight: 'normal' }}>{val}</div>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: t('status'),
    sortable: true,
    width: 130,
    renderCell: (params) => {
      const val = params.value || '';
      const strValue = params.value.replaceAll('_', ' ');
      const pillStyle = {
        background: STATUSES_PILL_BACKGROUND_COLOR[val.toLowerCase()],
        color: STATUSES_PILL_COLOR[val.toLowerCase()],
      };
      return (
        <div style={pillStyle} className="statusPill tableStatus" title={params.value}>
          <div style={{ lineHeight: 'normal' }}>{strValue}</div>
        </div>
      );
    },
  },
  {
    field: 'photo',
    headerName: t('photo'),
    sortable: false,
    width: 81,
    renderCell: (params) => {
      let result = <div />;
      if (params?.value?.url) {
        result = <img className="tableImage" alt="car" src={params.value.url} />;
      } else {
        result = <img className="tableImage" alt="car" src={nocar} />;
      }
      return result;
    },
  },
  { field: 'make', headerName: t('make'), sortable: true, width: 112 },
  {
    field: 'model',
    headerName: t('model'),
    minWidth: 120,
  },
  {
    field: 'year',
    headerName: t('year'),
    sortable: true,
    width: 79,
  },
  { field: 'vinNumber', headerName: t('vin'), width: 188 },
  { field: 'container', headerName: t('container'), minWidth: 120 },
  { field: 'containerLine', headerName: t('contLine'), minWidth: 110 },
  {
    field: 'eta',
    headerName: t('etaAta'),
    sortable: false,
    width: 114,
  },
];

const Tracking = ({ getToggleSidebar, statuses }) => {
  const context = useContext(AppContext);
  const { toggleLoader, token, navigateToLogin, search, setUser, setError } = context;
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [showSideBar, setShowSideBar] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const sortOrder = useRef(DEFAULT_SORT.sortOrder);
  const sortColumn = useRef(DEFAULT_SORT.sortColumn);
  const sideBarAction = useRef();
  const selectedRowData = useRef();
  const prevSelectedStatuses = useRef([]);

  const getUser = async () => {
    let result;
    try {
      toggleLoader(true);
      result = await axios.get(`${ENDPOINT}/webresources/auth/getUser?token=${token}`);
      toggleLoader(false);
    } catch (e) {
      navigateToLogin(t('sessionExpired'));
      toggleLoader(false);
      return;
    }
    const { data: userData } = result.data;
    if (userData) {
      append(require(`../localization/en`));
      setUser({
        role: userData?.role,
        permissions: userData?.permissions,
      });
    }
  };

  const getData = async (isStatusesFilterTrigger, page = pageNumber) => {
    if (
      isStatusesFilterTrigger &&
      JSON.stringify(selectedStatuses) === JSON.stringify(prevSelectedStatuses.current)
    ) {
      return;
    }
    let result;
    // eslint-disable-next-line
    try {
      prevSelectedStatuses.current = selectedStatuses;
      toggleLoader(true);
      const params = {
        matcher: search,
        page,
        sortColumn: sortColumn.current,
        sortOrder: sortOrder.current.toUpperCase(),
      };
      if (selectedStatuses && selectedStatuses.length > 0) {
        params.additionalFilters = { auctionOrderStatus: selectedStatuses };
      }
      result = await axios.post(`${ENDPOINT}/webresources/auction/findOrders/${token}`, params);
      toggleLoader(false);
    } catch (e) {
      setError(t('somethingWentWrong'));
      toggleLoader(false);
      return;
    }
    if (!result?.data?.data) return;
    const { content, page: newPage, recordsOnPage, totalRecords } = result.data.data;
    setPageNumber(newPage);
    setPerPage(recordsOnPage);
    setTotalCount(totalRecords);
    setData(prepareTrackingTableData(content));
  };

  const toggleSidebar = async (actionName, rowData, hideSidebar) => {
    sideBarAction.current = actionName;
    selectedRowData.current = rowData;
    const shouldHideSidebar = typeof hideSidebar !== 'undefined' ? hideSidebar : showSideBar;
    if (shouldHideSidebar) {
      if (actionName === STATUS_ACTION_NAME) {
        getData(true);
      }
      const menu = document.querySelector('.sidebar');
      const menuBackdrop = document.querySelector('.sidebarBackdrop');
      menu.style.right = '-25%';
      menuBackdrop.style.opacity = 0;
      setTimeout(() => {
        menu.style.width = '0';
        sideBarAction.current = null;
        selectedRowData.current = null;
        setShowSideBar(false);
        menu.style['min-width'] = 0;
      }, 300);
    } else {
      setShowSideBar(true);
    }
  };

  useEffect(() => {
    if (showSideBar) {
      const menu = document.querySelector('.sidebar');
      const menuBackdrop = document.querySelector('.sidebarBackdrop');
      menuBackdrop.style.opacity = 0.2;
      menu.style.right = '0';
      menu.style.width = '25%';
      setTimeout(() => {
        menu.style['min-width'] = '420px';
      }, 500);
    }
  }, [showSideBar]);

  // Everytime search in header changed(input blur or enter pressed) we do search data again
  useEffect(() => {
    getData();
  }, [search]);

  useEffect(() => {
    getToggleSidebar(() => toggleSidebar);
  }, [showSideBar, selectedStatuses]);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div style={{ width: 1370, marginTop: 60 }}>
      <DataGrid
        sx={{
          fontSize: 14,
          width: 1370,
          '& .MuiDataGrid-main': {
            height: 95 * perPage + 20 * perPage + 57, // all rows + margins + header
          },
          '& .tableRow': {
            cursor: 'pointer',
            marginTop: '20px',
            marginBottom: '20px',
            width: '1370px !important',
            maxWidth: '1370px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            minHeight: '23px !important',
            maxHeight: '23px !important',
            lineHeight: '23px !important',
            borderBottom: 0,
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '43px !important',
          },
          '& .tableRow:first-child': {
            marginTop: 0,
          },
          '& .tableRow:last-child': {
            marginBottom: 0,
          },
          '& .tableCell': {
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
            lineHeight: 'normal !important',
            whiteSpace: 'break-spaces',
          },
          '& .tableCell, & .MuiDataGrid-columnHeader': {
            boxSizing: 'content-box',
          },
          '& .tableCell, & .tableCell:focus': {
            outline: 'none',
          },
          '& .tableCell:first-child, & .MuiDataGrid-columnHeader:first-child': {
            paddingLeft: '20px',
          },
          '& .tableCell:last-child, .MuiDataGrid-columnHeader:last-child': {
            paddingRight: '20px',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
          },
        }}
        rows={data}
        columns={getMeta(toggleSidebar)}
        pageSize={perPage}
        rowCount={totalCount}
        paginationMode="server"
        sortingMode="server"
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableVirtualization
        disableColumnResize
        disableSelectionOnClick
        rowHeight={95}
        onSortModelChange={(sort) => {
          if (sort && sort[0]) {
            sortColumn.current = mapSortColumn(sort[0].field);
            sortOrder.current = sort[0].sort;
          } else {
            sortColumn.current = DEFAULT_SORT.sortColumn;
            sortOrder.current = DEFAULT_SORT.sortOrder;
          }
          getData();
        }}
        components={{
          ColumnSortedAscendingIcon: KeyboardArrowDown,
          ColumnSortedDescendingIcon: KeyboardArrowUp,
          ColumnUnsortedIcon: null,
        }}
        onPageChange={(page) => {
          getData(false, page + 1); // As data grid starts with page = 0
        }}
        onRowClick={(rowData) => toggleSidebar(DOCUMENTS_ACTION_NAME, rowData.row)}
        getRowClassName={(params) => `tableRow tableRow-${params.getValue(params.id, 'status')}`}
        getCellClassName={() => 'tableCell'}
      />
      {showSideBar && [
        <div className="sidebarBackdrop" />,
        <Sidebar
          toggleSidebar={toggleSidebar}
          data={selectedRowData.current}
          contentType={sideBarAction.current}
          statuses={statuses}
          getTableData={getData}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
        />,
      ]}
    </div>
  );
};

Tracking.propTypes = {
  getToggleSidebar: PropTypes.func,
  statuses: PropTypes.arrayOf(PropTypes.string),
};

export default Tracking;
