import React, { useState, useContext } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PhoneInput from './PhoneInput';
import { append, t } from '../i18n';
import AppContext from '../AppContext';
import { ENDPOINT } from '../constants';
import '../app.scss';
import logo from '../images/logo-slug.png';

const SignIn = () => {
  const context = useContext(AppContext);
  const { toggleLoader, setToken, setUser, setError } = context;

  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  // eslint-disable-next-line
  const [rememberMe, setRememberMe] = useState(false);

  const onSignIn = async () => {
    let result;
    if (!phone || !password) {
      setError(t('typePhoneAndPassword'));
      return;
    }
    try {
      toggleLoader(true);
      // TODO implement usage of remember me and send it to backend
      result = await axios.post(`${ENDPOINT}/webresources/auth/login`, {
        userId: `+${phone}`, // '+380935555555'
        password, // Admin123
        // rememberMe, // TODO implement usage of remember me and send it to backend
      });
    } catch (e) {
      console.log(e);
      setError(t('wrongPhoneOrPassword'));
      toggleLoader(false);
      return;
    }
    const data = result?.data?.data;
    append(`../localization/${data.locale}`);
    setToken(data?.token);
    setUser({
      role: data?.role,
      permissions: data?.permissions,
    });
    localStorage.setItem('tracking-auth-token', data?.token);
    toggleLoader(false);
  };

  return (
    <div className="signIn">
      <div className="logoWrapper">
        <img className="logo" src={logo} alt="" />
      </div>
      <div className="formWrapper">
        <div className="formInnerWrapper">
          <div className="title">{t('adminPanel')}</div>
          <PhoneInput onChange={(value) => setPhone(value)} />
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            className="fieldInput"
            type="password"
            label={t('password')}
            variant="outlined"
          />
          <FormControlLabel
            className="rememberMe"
            control={<Checkbox onChange={(event, val) => setRememberMe(val)} />}
            label={t('rememberMe')}
          />
          <Button onClick={onSignIn} className="button" variant="contained">
            {t('signIn')}
          </Button>
          <a href="#" className="forgotPass">
            {t('forgotPassword')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
