import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';

const StyledTextField = withStyles({
  root: {
    background: '#fff',
    borderRadius: 5,
    height: 37,
    width: 303,
    '& .MuiFormLabel-root': {
      color: '#fff', // label
    },
    '& .MuiSvgIcon-root': {
      color: '#acb0bb', // left icon
    },
    '& .MuiFilledInput-input': {
      color: '#acb0bb', // input text
      padding: 0,
      fontSize: 14,
      fontWeight: 400,
      paddingLeft: 5,
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
      marginTop: '0 !important',
    },
    '& .MuiFilledInput-root': {
      height: '100%',
      width: '100%',
      backgroundColor: 'unset',
      padding: '10px 20px',
    },
    '& .MuiFilledInput-root:before': {
      border: 'none',
    },
    '& .MuiFilledInput-root:hover:before': {
      border: 'none',
    },
    '& .MuiFilledInput-root:after': {
      border: 'none',
    },
    '& .MuiFilledInput-root::-webkit-clear-button, & .MuiInput-underline::-webkit-outer-spin-button, & .MuiInput-underline::-webkit-inner-spin-button':
      {
        display: 'none',
      },
  },
})(TextField);

const HeaderSearchTextField = (props) => (
  <StyledTextField
    InputProps={{
      sx: { color: '#fff' },
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
    variant="filled"
    {...props}
  />
);

export default HeaderSearchTextField;
