import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';
import SidebarField from './SidebarField';
import { t } from '../i18n';
import { FIELD_TYPE_CURRENCY, FIELD_TYPE_FILE, SECTION } from '../constants';
import { formatDateFromString } from '../utils';

const SidebarFields = ({ fields, getCloseSidebarImageViewer, data }) => {
  const [fieldOpened, setFieldOpened] = useState();
  const [isSidebarViewerOpened, setIsSidebarViewerOpened] = useState(false);

  const renderField = (field, fieldIndex, ddata) => {
    const { title, name, subTitle, type, items, isHistory, isFiles } = field;
    let fieldMeta = items;
    let fieldData = ddata;
    if (type === SECTION) {
      // For History type
      if (isHistory) {
        fieldMeta = [];
        fieldData = {};
        data[name].forEach((item, index) => {
          const metaKey = `value${index}`;
          fieldMeta.push({
            label: formatDateFromString(item.lastUpdated),
            name: metaKey,
            type: FIELD_TYPE_CURRENCY,
          });
          fieldData[metaKey] = item.value;
        });
        fieldMeta.push({
          label: t('totalIncome'),
          name: 'totalIncome',
          type: FIELD_TYPE_CURRENCY,
          textColor: '#219653',
          boldLabel: true,
        });
        fieldData.totalIncome = Object.keys(fieldData)
          .map((key) => fieldData[key])
          .reduce((a, b) => {
            if (typeof a === 'undefined' && b) return b;
            if (typeof b === 'undefined' && a) return a;
            if (typeof b === 'undefined' && typeof b === 'undefined') return 0;
            return a + b;
          }, 0); // Calculate total income as sum of all history items
      }
      // For Files type
      if (isFiles) {
        fieldMeta = [];
        fieldData = {};
        data[name].forEach((item, index) => {
          const metaKey = `value${index}`;
          fieldMeta.push({
            label: item.name,
            name: metaKey,
            type: FIELD_TYPE_FILE,
          });
          fieldData[metaKey] = item.url;
        });
      }
      return (
        <div className="sidebarFieldsSection">
          {title && <div className="title">{title}</div>}
          {subTitle && <div className="subTitle">{subTitle}</div>}
          <div className="fieldsWrapper">
            {fieldMeta.map((fieldItem, index) => renderField(fieldItem, index, fieldData))}
          </div>
        </div>
      );
    }
    return (
      <SidebarField
        fieldOpened={fieldOpened}
        setFieldOpened={setFieldOpened}
        field={field}
        data={fieldData}
        isSidebarViewerOpened={isSidebarViewerOpened}
        setIsSidebarViewerOpened={setIsSidebarViewerOpened}
        fieldIndex={fieldIndex}
      />
    );
  };

  useEffect(() => {
    getCloseSidebarImageViewer(() => setIsSidebarViewerOpened(false));
  }, []);

  return (
    <div className="sidebarFields">
      {fields.map((fieldItem, fieldIndex) => renderField(fieldItem, fieldIndex, data))}
      {isSidebarViewerOpened && (
        <div className="sidebarImageViewer">
          <ImageViewer
            src={window.sidebarImageViewerImages}
            currentIndex={0}
            onClose={() => {
              setIsSidebarViewerOpened(false);
              setFieldOpened(null);
            }}
            disableScroll
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}
          />
        </div>
      )}
    </div>
  );
};

SidebarFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape),
  getCloseSidebarImageViewer: PropTypes.bool,
  data: PropTypes.shape,
};

export default SidebarFields;
