import { DOCUMENT_BOX_TYPE, INVOICE_BOX_TYPE } from './constants';

export const formatDateFromString = (date) => {
  if (!date) return null;
  return new Date(Date.parse(date)).toLocaleDateString();
};

export const mapSortColumn = (sortColumn) => {
  const map = {
    order: 'orderNumber',
    status: 'auctionOrderStatus',
    model: 'car.model',
    year: 'car.year',
    make: 'car.brand',
    vinNumber: 'car.vin',
    container: 'containerNumber',
    eta: 'etaDate',
  };
  return map[sortColumn] ? map[sortColumn] : sortColumn;
};

export const prepareDataObj = (item) => {
  const { car } = item;
  return {
    id: item.id,
    order: item.orderNumber,
    status: item.auctionOrderStatus, // TODO maybe create and use value => label map
    photo: car.photos && car.photos.length > 0 && car.photos[0],
    make: car.brand,
    model: car.model,
    year: car.year,
    vinNumber: car.vin,
    container: item.containerNumber,
    containerLine: item.containerLine,
    eta: formatDateFromString(item.etaDate),
    docs: {
      order: item.orderNumber,
      container: item.containerNumber,
      vinNumber: car.vin,
      // TODO remove map later when name will be not empty from backend
      documents: car.documents
        .filter((doc) => doc.type === DOCUMENT_BOX_TYPE)
        .map((doc) => ({ ...doc, name: 'Test doc' })),
      // TODO remove map later when name will be not empty from backend
      invoices: car.documents
        .filter((doc) => doc.type === INVOICE_BOX_TYPE)
        .map((doc) => ({ ...doc, name: 'Test doc' })),
    },
    car: {
      auction: item.auction,
      lotNumber: item.lot,
      date: formatDateFromString(item.auctionDate),
      title: car.title === true ? 'Yes' : 'No',
      key: car.keys === true ? 'Yes' : 'No', // TODO is that how it should be ?
      // TODO photos
      auctionPhotos: car.photos, // TODO where to take photos ?
      polsPhotos: [], // TODO where to take photos ?
      podsPhotos: [], // TODO where to take photos ?
    },
    pricing: {
      manager: item.manager,
      customer: null, // TODO where to get customer ?
      brokerPrice: item.brokerPrice,
      lotPrice: item.lotPrice,
      taxes: item.taxes,
      logPrice: item.logPrice,
      history: item.paymentHistory,
      balance: -100, // TODO where to get balance ?
      credit: item.credit,
      totalPrice: item.totalPrice,
    },
    shipping: {
      dispatchPlace: item.dispatchPlace,
      loadDate: formatDateFromString(item.loadDate),
      pol: item.loadingPort,
      shipDate: formatDateFromString(item.shipDate),
      pod: item.destinationPort,
      eta: formatDateFromString(item.etaDate),
      releasedDate: formatDateFromString(item.dateGivenAway),
    },
  };
};

export const prepareTrackingTableData = (initialData) =>
  initialData.map((item) => prepareDataObj(item));

export const prepareUsersTableData = (initialData) =>
  initialData.map((item) => {
    const { manager, role } = item;
    let status = 'Unregistered';
    if (item.password && item.activity) {
      status = 'Active';
    } else if (item.activity === false) {
      status = 'Inactive';
    } else if (item.activationHash) {
      status = 'Pending';
    }
    return {
      id: item.id,
      lastName: item.lastName,
      firstName: item.firstName,
      phone: item.phone,
      email: item.email,
      role: role?.roleName,
      actions: status,
      manager: manager ? manager.firstName + manager.lastName : '',
      status,
    };
  });

export const prepareStatuses = (data) =>
  data.map((status) => ({ name: status, label: status.replaceAll('_', ' ') }));

export function itemInObjByString(data, field) {
  let preparedData = { ...data };
  let name = field.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  name = name.replace(/^\./, ''); // strip a leading dot
  name = name.toLowerCase();
  const sequences = name.split('.');
  const setPreparedData = (objName, key) => {
    if (objName === key.toLowerCase()) {
      preparedData = preparedData[key];
    }
  };
  for (let i = 0, n = sequences.length; i < n; i += 1) {
    const objName = sequences[i];
    if (
      preparedData &&
      Object.keys(preparedData).length > 0 &&
      Object.keys(preparedData)
        .map((key) => key.toLowerCase())
        .indexOf(objName) !== -1
    ) {
      Object.keys(preparedData).forEach((key) => setPreparedData(objName, key));
    } else {
      return null;
    }
  }
  return preparedData;
}
