import React, { useState } from 'react';
import Header from './Header';
import Tracking from './Tracking';
import Calculator from './Calculator';
import Users from './Users';
import { VIEW_TRACKING, VIEW_CALCULATOR, VIEW_USERS } from '../constants';

const Dashboard = () => {
  const [view, setView] = useState(VIEW_TRACKING);
  const [toggleSidebar, setToggleSidebar] = useState();
  const [statuses, setStatuses] = useState();

  const renderView = () => {
    let result;
    switch (view) {
      case VIEW_TRACKING:
        result = (
          <Tracking statuses={statuses} getToggleSidebar={(func) => setToggleSidebar(func)} />
        );
        break;
      case VIEW_CALCULATOR:
        result = <Calculator />;
        break;
      case VIEW_USERS:
        result = <Users />;
        break;
      default:
        result = (
          <Tracking statuses={statuses} getToggleSidebar={(func) => setToggleSidebar(func)} />
        );
        break;
    }
    return result;
  };

  return (
    <div stlye={{ color: 'black' }}>
      <Header
        view={view}
        onMenuClick={(val) => {
          setView(val);
        }}
        toggleSidebar={toggleSidebar}
        setStatuses={setStatuses}
      />
      <div className="contentWrapper">{renderView()}</div>
    </div>
  );
};

export default Dashboard;
