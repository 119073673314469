import React, { useCallback, useEffect, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { append } from './i18n';
import Details from './components/Details';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';
import { AppContextProvider } from './AppContext';

append(require('./localization/en.json'));

let loaderCount = 0;
const THEME = createTheme({
  typography: {
    allVariants: {
      fontSize: 14,
      fontFamily: 'Montserrat',
    },
  },
});

const App = () => {
  const [contextData, setContextData] = useState({});
  const [showLoader, setShowLoader] = useState(loaderCount);
  const [token, setToken] = useState(localStorage.getItem('tracking-auth-token'));
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [search, setSearch] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('tracking-auth-token'));

  const toggleLoader = useCallback(
    (show) => {
      loaderCount = show ? loaderCount + 1 : loaderCount - 1;
      setShowLoader(loaderCount);
    },
    [setShowLoader],
  );

  const navigateToLogin = useCallback(
    (errorMsg) => {
      localStorage.removeItem('tracking-auth-token');
      setIsLoggedIn(false);
      setToken(null);
      if (errorMsg) setError(errorMsg);
    },
    [setToken, setIsLoggedIn],
  );

  const setContext = () => {
    setContextData({
      ...contextData,
      toggleLoader,
      token,
      setToken,
      user,
      setUser,
      setIsLoggedIn,
      setError,
      navigateToLogin,
      search,
      setSearch,
    });
  };

  useEffect(() => {
    setContext();
    if (token) setIsLoggedIn(true);
  }, [
    token,
    setToken,
    setIsLoggedIn,
    toggleLoader,
    user,
    setUser,
    setError,
    navigateToLogin,
    setSearch,
    search,
  ]);

  useEffect(() => {
    setContext();
    setLoaded(true);
  }, []);

  return !loaded ? null : (
    <ThemeProvider theme={THEME}>
      <AppContextProvider value={contextData}>
        <div className="mainContainer">
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoader > 0}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {error && (
            <div className="alertWrapper">
              <Alert className="alert" onClose={() => setError(null)} severity="error">
                {error}
              </Alert>
            </div>
          )}
          <HashRouter>
            <Routes>
              <Route path="/" element={<div>{isLoggedIn ? <Dashboard /> : <SignIn />}</div>} />
              <Route path="details/:orderNum" element={<Details />} />
            </Routes>
          </HashRouter>
        </div>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default App;
