import React from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-material-ui';
import TextField from '@mui/material/TextField';
import { withStyles } from '@material-ui/core';
import { t } from '../i18n';

const styles = (theme) => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const PhoneInput = ({ value, onChange }) => (
  <ReactPhoneInput
    value={value}
    country="ua"
    onChange={onChange}
    component={TextField}
    disableDropdown
    specialLabel=""
    inputProps={{
      variant: 'outlined',
      placeholder: '',
      label: t('phoneNumber'),
      className: 'fieldInput',
    }}
  />
);

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default withStyles(styles)(PhoneInput);
