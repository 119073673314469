import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Assignment,
  DirectionsCar,
  MonetizationOn,
  DirectionsBoat,
  Close,
  Share,
} from '@mui/icons-material';
import nocar from '../images/nocar.png';
import SidebarFields from './SidebarFields';
import SidebarStatuses from './SidebarStatuses';
import { t } from '../i18n';
import {
  DOCUMENTS_ACTION_NAME,
  CAR_ACTION_NAME,
  PRICING_ACTION_NAME,
  SHIPPING_ACTION_NAME,
  CHAT_ACTION_NAME,
  STATUS_ACTION_NAME,
  CAR_ACTION_NAME_FIELDS,
  SHIPPING_ACTION_NAME_FIELDS,
  PRICING_ACTION_NAME_FIELDS,
  DOCUMENTS_ACTION_NAME_FIELDS,
  SECTION,
  STATUSES_PILL_BACKGROUND_COLOR,
  STATUSES_PILL_COLOR,
} from '../constants';
import { ReactComponent as Chat } from '../images/chat.svg';

export const SIDEBAR_ACTIONS = [
  {
    name: DOCUMENTS_ACTION_NAME,
    component: <Assignment fontSize="medium" />,
  },
  {
    name: CAR_ACTION_NAME,
    component: <DirectionsCar fontSize="medium" />,
  },
  {
    name: PRICING_ACTION_NAME,
    component: <MonetizationOn fontSize="medium" />,
  },
  {
    name: SHIPPING_ACTION_NAME,
    component: <DirectionsBoat fontSize="medium" />,
  },
  {
    name: CHAT_ACTION_NAME,
    component: <Chat />,
  },
];

const Sidebar = ({
  toggleSidebar,
  contentType,
  data,
  statuses,
  getTableData,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  const closeSidebarImageViewer = useRef();
  const sidebarRef = useRef();
  const [activeAction, setActiveAction] = useState(contentType);
  const pillStyle = {
    background: STATUSES_PILL_BACKGROUND_COLOR[data?.status?.toLowerCase()],
    color: STATUSES_PILL_COLOR[data?.status?.toLowerCase()],
  };

  const getCloseSidebarImageViewer = (func) => {
    closeSidebarImageViewer.current = func;
  };

  const renderTop = () => {
    let carphotourl = data?.photo.url;
    if (!carphotourl) {
      carphotourl = nocar;
    }

    if (activeAction !== STATUS_ACTION_NAME) {
      return (
        <div className="sidebarTopWrapper">
          <div className="sidebarTop">
            <div className="sidebarTopActions">
              {SIDEBAR_ACTIONS.map((action) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={`action actionIcon ${
                    activeAction === action.name ? 'actionIconActive' : ''
                  }`}
                  onClick={() => setActiveAction(action.name)}
                >
                  {action.component}
                </div>
              ))}
            </div>
            <Close
              fontSize="large"
              onClick={() => {
                if (closeSidebarImageViewer.current) closeSidebarImageViewer.current();
                if (contentType === STATUS_ACTION_NAME) {
                  getTableData(true);
                }
                toggleSidebar();
              }}
              className="closeSidebar"
            />
          </div>

          <div className="sidebarCarInfo">
            <div className="carInfoPhoto">
              <img className="sidebarCarImage" alt="car" src={carphotourl} />
            </div>
            <div className="carInfoRight">
              <div className="carInfoTop">
                <div className="carInfoTitle">{`${data?.make} ${data?.model}`}</div>
                <Link target="_blank" to={`/details/${data.order}`}>
                  <div role="button" tabIndex={0} className="action actionIcon actionShare">
                    <Share />
                  </div>
                </Link>
              </div>
              <div className="carInfoBottom">
                <div className="carInfoYear">{data?.year}</div>
                <div style={pillStyle} className="statusPill carInfoStatus" title={data?.status}>
                  <div style={{ lineHeight: 'normal' }}>{data?.status}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return '';
  };

  const renderContent = () => {
    let fields = [];
    let fieldsData = {};
    // TODO open tab from right here.
    switch (activeAction) {
      case DOCUMENTS_ACTION_NAME:
        fields = DOCUMENTS_ACTION_NAME_FIELDS;
        fieldsData = data?.docs;
        break;
      case CAR_ACTION_NAME:
        fields = CAR_ACTION_NAME_FIELDS;
        fieldsData = data?.car;
        break;
      case PRICING_ACTION_NAME:
        fields = PRICING_ACTION_NAME_FIELDS;
        fieldsData = data?.pricing;
        break;
      case SHIPPING_ACTION_NAME:
        fields = SHIPPING_ACTION_NAME_FIELDS.map((item, index) => {
          const result = { ...item };
          if (index === 0 && item.type === SECTION) {
            result.subTitle = `${data?.make} ${data?.model} ${data?.year}`;
          }
          return result;
        });
        fieldsData = data?.shipping;
        break;
      case STATUS_ACTION_NAME:
        return (
          <SidebarStatuses
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            statuses={statuses}
          />
        );
      case CHAT_ACTION_NAME:
        return 'TODO chat here';
      default:
        break;
    }
    fields = fields.map((field) => {
      const result = { ...field, title: t(field.title), label: t(field.label) };
      if (result.items?.length > 0) {
        result.items = result.items.map((subField) => ({ ...subField, label: t(subField.label) }));
      }
      return result;
    });
    return (
      <SidebarFields
        getCloseSidebarImageViewer={getCloseSidebarImageViewer}
        fields={fields}
        data={fieldsData}
      />
    );
  };

  const handleClickOutside = (event) => {
    if (!sidebarRef.current || !sidebarRef.current.contains(event.target)) {
      toggleSidebar(null, null, true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="sidebar" ref={sidebarRef}>
      <div className="sidebarWrapper">
        {renderTop()}
        {renderContent()}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func,
  contentType: PropTypes.string,
  data: PropTypes.shape,
  statuses: PropTypes.arrayOf(PropTypes.string),
  getTableData: PropTypes.func,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  setSelectedStatuses: PropTypes.func,
};

export default Sidebar;
